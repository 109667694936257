<template>
  <footer>
    <div class="info_container">
      <div id="logo_container">
        <img src="@/assets/logo_white.svg" alt="Logo" />
        <p>
          Sindicato dos Empregados em Estabelecimentos Bancários de Uberlândia e
          Região
        </p>
        <h4>Presidente: Edivaldo Dias Cunha</h4>
      </div>
      <div id="contato_container">
        <h3>Contato</h3>
        <div>
          <img src="@/assets/map_icon_dark.svg" alt="Icone de Mapa" />
          <p>R. Duque de Caxias, 95 - Centro Uberlândia - MG, 38400-142</p>
        </div>
        <div>
          <img src="@/assets/celphone_icon_dark.svg" alt="Icone de Telefone" />
          <p>
            (34) 3236 7277
            <br />
            (34) 3236-3803 - Fax
          </p>
        </div>
        <div>
          <img src="@/assets/message_icon_dark.svg" alt="Icone de Mensagem" />
          <p>bancariosuberlandia@gmail.com</p>
        </div>
      </div>
      <div class="map">
        <iframe
          allowfullscreen
          aria-hidden="false"
          frameborder="0"
          height="100%"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3774.301315466853!2d-48.28290108462407!3d-18.918052487178667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a44454e404aa7f%3A0xceb4948e82d6d372!2sSindicato%20dos%20Bancarios%20de%20Uberl%C3%A2ndia%20e%20Regiao!5e0!3m2!1spt-BR!2sbr!4v1594249370522!5m2!1spt-BR!2sbr"
          style="border: 0; display: block"
          tabindex="0"
          width="100%"
        />
      </div>
    </div>

    <div class="footer_copywright">
      <p>Copyright © {{year}} Todos os direitos reservados de SEEB</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
footer {
  padding: 0px 20px;
  background-image: url("../../../assets/footer_background.svg");
  background-repeat: no-repeat;
  background-size: cover;

  p {
    font-family: "Inter";
    font-size: 16px;
    color: white;
  }
  #logo_container {
    max-width: 285px;

    p {
      margin-top: 5px;
    }

    h4 {
      color: white;
      font-family: "Inter";
      font-size: 16px;
      margin-top: 22px;
      letter-spacing: 0.8px;
    }
  }
  .info_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1230px;
    margin: 0 auto;
    margin-top: 140px;
    margin-bottom: 20px;
  }
  .footer_copywright {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1230px;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 15px;

    p {
      font-weight: 300;
    }
  }
  #contato_container {
    max-width: 332px;

    h3 {
      color: white;
      text-transform: uppercase;
      font-family: "Raleway";
      font-size: 16px;
      padding-left: 10px;
      border-left: 2px solid white;
      border-radius: 1px;
    }
    div {
      display: flex;
      align-items: center;
      margin: 21px 0px;
      p {
        margin-bottom: 0px;
        margin-left: 16px;
      }
    }
  }
  .map iframe {
    width: 313px;
    height: 308px;
    border-radius: 6px;
  }

  @media screen and (max-width: 724px) {
    padding: 0px;

    #logo_container {
      text-align: center;
      max-width: 300px;

      p {
        margin-top: 15px;
      }
    }

    .footer_copywright {
      justify-content: center;
    }
  }

  @media screen and (max-width: 987px) {
    background: rgba(33, 31, 37, 1);

    .info_container {
      justify-content: center;
      gap: 50px;
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 1460px) {
    background-position: center;
  }

  @media screen and (min-width: 1950px) {
    background: rgba(33, 31, 37, 1);

    .info_container {
      margin-top: 30px;
    }
  }
}
</style>
